import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import Panel from 'src/components/common/panel/Panel'
import AnnounceUi from 'src/components/ui/announce/Announce'
import Button from 'src/components/ui/button/Button'
import CopyButton from 'src/components/ui/copyButton/CopyButton'
import Input from 'src/components/ui/input/Input'
import Loader from 'src/components/ui/loader/Loader'
import TooltipUi from 'src/components/ui/tooltip/TooltipUi'
import useModal from 'src/hooks/useModal'
import { PAYMENT } from 'src/routes/data'
import {
	AutopayChangeRequest,
	AutopayConfirm,
	BaseResponse,
	ResponseAutopayConfirm
} from 'src/types/swagger'

import './AutopaymentEdit.scss'

const AutoPaymentEdit: FC = () => {
	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		setValue,
		getValues
	} = useForm({ mode: 'onChange' })

	const { id } = useParams()
	const { open } = useModal()
	const [data, setData] = useState<AutopayConfirm | null>(null)
	const [isLoading, setIsLoading] = useState(true)

	//Первичный запрос
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseAutopayConfirm> = await $api.get(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/autopay/${id}/`
				)
				const { data } = response.data

				if (data) {
					setData(data)
					setValue('email', data.email)
					setValue('auto_payment_amount', data.autopay.amount || 10)
					setValue('day', data.autopay.debit_date || 1)
				}
			} catch (error: any) {
				console.error('Failed to fetch autoPaymen edit:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичный запрос

	//Отпрвка измененных данных
	const onSubmit = () => {
		setIsLoading(true)

		const body: AutopayChangeRequest = {
			amount: getValues('auto_payment_amount'),
			date: getValues('day')
		}
		const fetchData = async () => {
			try {
				const response: AxiosResponse<BaseResponse> = await $api.put(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/autopay/${id}/`,
					body
				)

				const { data } = response
				open({
					text: data?.data?.title || data?.client_message || 'Изменения сохранены',
					modalType: 'success',
					isReload: true,
					description: data?.data?.description || ''
				})
			} catch (error: any) {
				console.info('error', error)
				open({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}
		fetchData()
	}
	//Отпрвка измененных данных

	return (
		<>
			<Loader state={isLoading} />
			{data && (
				<div className="payment-page payment-page--page react-autopayment-new r-autopayment-edit">
					<Panel text={'Оплата'} linkText={'Оплата'} href={PAYMENT + '/autopay'} />
					<section className="section section--first-panel" data-real-tabs="">
						<div className="container">
							{data.announces && (
								<div className="react-autopayment-new__announce">
									{data.announces.map(item => (
										<AnnounceUi key={item.announce_id} text={item.message} />
									))}
								</div>
							)}

							<form className="payment-page__row" onSubmit={handleSubmit(onSubmit)}>
								<div className="payment-page__col autopay">
									<div className="section__container payment-page__column">
										<div className="payment-page__wrapper">
											<div className="payment-page__column-top">
												<h2 className="section__title">
													Настройка автоплатежа
												</h2>
												<div className="autopay__copy">
													<div className="payment-page__label">
														Номер договора
													</div>
													<div className="payment-page__refill-copy">
														<CopyButton
															text={data.agreement}
															size={true}
															variant={2}
														/>
													</div>
												</div>
												<div className="autopay__bot">
													<div className="autopay__title">
														Выбор даты и суммы списания
													</div>
													<div className="autopay__grid">
														{/* //Дата пополнения */}

														<div className="autopay__item">
															<div className="react-autopay__item">
																<p>День</p>
																{data.tooltip_date && (
																	<TooltipUi
																		text={data.tooltip_date}
																	/>
																)}
															</div>
															<Input
																register={register}
																filled={!!watch('day')}
																name="day"
																error={errors.day}
																options={{
																	required: 'Заполните поле',
																	min: {
																		value: 1,
																		message: 'Некорректная дата'
																	},
																	max: {
																		value: 31,
																		message: 'Некорректная дата'
																	}
																}}
																type="number"
															/>
														</div>
														{/* //Дата пополнения */}

														<div className="autopay__item">
															<div className="react-autopay__item">
																<p>Сумма пополнения</p>

																{data.tooltip_date && (
																	<TooltipUi
																		text={data.tooltip_amount}
																	/>
																)}
															</div>
															<Input
																register={register}
																filled={
																	!!watch('auto_payment_amount')
																}
																name="auto_payment_amount"
																error={errors.auto_payment_amount}
																options={{
																	required: 'Заполните поле',
																	min: {
																		value: 10,
																		message:
																			'Минимальная сумма 10 рублей'
																	},
																	max: {
																		value: 30000,
																		message:
																			'Максимальная сумма 30 000 рублей'
																	}
																}}
																type="number"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="payment-page__col">
									<div className="section__container payment-page__column">
										<div className="payment-page__pay">
											<div className="r-autopayment-edit__block border-bottom">
												<div className="payment-page__pay-row">
													<div className="payment-page__pay-heading">
														Сумма платежа:
													</div>
													<div className="payment-page__pay-price">
														{watch('auto_payment_amount')} ₽
													</div>
												</div>

												<div className="payment-page__pay-offset">
													<div className="payment-page__pay-row">
														<div className="payment-page__pay-heading">
															День платежа:
														</div>
														<div className="payment-page__pay-price">
															{watch('day')}
														</div>
													</div>
												</div>
											</div>

											<div className="payment-page__pay-offset">
												<div className="payment-page__pay-heading">
												Email адрес
												</div>
											</div>
											<div className="payment-page__pay-offset">
												<div className="autopay__item">
													<div className="react-autopay__item">
														<p>Способ доставки чека</p>

														{data.tooltip_check_delivery && (
															<TooltipUi
																text={data.tooltip_check_delivery}
															/>
														)}
													</div>

													<Input
														register={register}
														filled={!!watch('email')}
														name="email"
														error={errors.email}
														options={{
															required: 'Заполните поле',
															pattern: {
																value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
																message: 'Неверный формат'
															}
														}}
													/>
												</div>
											</div>

											<div className="payment-page__pay-offset">
												<Button
													text={'Сохранить'}
													onClick={handleSubmit(onSubmit)}
												/>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</section>
				</div>
			)}
		</>
	)
}

export default AutoPaymentEdit
