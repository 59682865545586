import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import SidebarRight from 'src/components/common/sidebarRight/SidebarRight'
import { eventEmitter } from 'src/features/error-handler'
import { useAppDispatch, useAppSelector } from 'src/hooks/redusHooks'
import { asyncLogout } from 'src/redux/slices/appSlice'
import { PAYMENT, PROFILE } from 'src/routes/data'

interface IProps {
	state: boolean
	setState: (value: any) => void
}

const ProfileSidebar: FC<IProps> = ({ state, setState }) => {
	const navigate = useNavigate()
	const { clientData } = useAppSelector(state => state.client)

	//Обработчик клика по ссылкам
	const handleLinkClick = (variant: 'payment' | 'profile') => {
		if (variant === 'payment') {
			navigate(PAYMENT + '/balance')
		} else {
			navigate(PROFILE)
		}
		setState(false)
	}
	//Обработчик клика по ссылкам

	// logout
	const logoutClick = () => {
		eventEmitter.emit('request-error', {
			action: 'logout'
		})
	}
	// logout

	return (
		<SidebarRight state={state} setState={setState}>
			<div
				className="profile-modal side-modal"
				data-fancy-modal="profile"
				style={{ display: 'inline-block' }}
			>
				<div className="side-modal__top">
					<div
						className="side-modal__icon hover"
						onClick={() => {
							setState((prev: boolean) => !prev)
						}}
					>
						<svg
							className="side-modal__svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19 10.9998H9L12.29 7.70981C12.3837 7.61685 12.4581 7.50625 12.5089 7.38439C12.5597 7.26253 12.5858 7.13183 12.5858 6.99981C12.5858 6.8678 12.5597 6.7371 12.5089 6.61524C12.4581 6.49338 12.3837 6.38278 12.29 6.28982C12.1026 6.10356 11.8492 5.99902 11.585 5.99902C11.3208 5.99902 11.0674 6.10356 10.88 6.28982L6.59 10.5898C6.21441 10.9632 6.00223 11.4702 6 11.9998C6.00487 12.5259 6.21684 13.0289 6.59 13.3998L10.88 17.6998C10.9732 17.7924 11.0838 17.8657 11.2054 17.9155C11.3269 17.9654 11.4571 17.9908 11.5885 17.9904C11.7199 17.9899 11.8499 17.9636 11.9712 17.9128C12.0924 17.8621 12.2024 17.788 12.295 17.6948C12.3876 17.6016 12.4609 17.491 12.5107 17.3694C12.5606 17.2479 12.586 17.1177 12.5856 16.9863C12.5851 16.8549 12.5588 16.7249 12.508 16.6036C12.4573 16.4824 12.3832 16.3724 12.29 16.2798L9 12.9998H19C19.2652 12.9998 19.5196 12.8945 19.7071 12.7069C19.8946 12.5194 20 12.265 20 11.9998C20 11.7346 19.8946 11.4802 19.7071 11.2927C19.5196 11.1052 19.2652 10.9998 19 10.9998Z"
								fill="#0076D8"
							/>
						</svg>
					</div>
					<div className="side-modal__text">Профиль</div>
				</div>
				<div className="profile-modal__inner">
					<div className="profile-modal__top">
						<div className="profile-modal__title">{clientData?.data.fio}</div>
						<div className="profile-modal__row">
							<div className="profile-modal__col">
								<div className="profile-modal__text">Ваш баланс</div>
								<div className="profile-modal__balance">
									{clientData?.data?.binfo.balance} ₽
								</div>
							</div>
							<div className="profile-modal__col">
								<div
									className="profile-modal__link cursor-pointer hover-blue"
									onClick={() => handleLinkClick('payment')}
								>
									Пополнить
								</div>
							</div>
						</div>
					</div>
					<div className="profile-modal__body">
						<div className="profile-modal__item">
							<div className="profile-modal__item-link">
								<div className="profile-modal__icon">
									<svg
										className="profile-modal__svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5.121 17.804C7.21942 16.6179 9.58958 15.9963 12 16C14.5 16 16.847 16.655 18.879 17.804M15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
											stroke="#001F38"
											strokeWidth={2}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
								<div
									className="profile-modal__item-text"
									onClick={() => handleLinkClick('profile')}
								>
									Управление профилем
								</div>
							</div>
						</div>
						<div className="profile-modal__item">
							<div className="profile-modal__item-link">
								<div className="profile-modal__icon">
									<svg
										className="profile-modal__svg"
										xmlns="http://www.w3.org/2000/svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M21.0175 9.56547L17.7522 6.24061C17.5934 6.08448 17.3808 5.99808 17.1601 6.00003C16.9394 6.00199 16.7283 6.09213 16.5722 6.25105C16.4161 6.40997 16.3276 6.62495 16.3257 6.84969C16.3237 7.07443 16.4086 7.29095 16.5619 7.45261L19.8272 10.7775C19.9242 10.8782 20.009 10.9904 20.0798 11.1118C20.0671 11.1118 20.057 11.1049 20.0444 11.1049L6.84179 11.1323C6.61853 11.1323 6.40442 11.2226 6.24655 11.3834C6.08869 11.5441 6 11.7621 6 11.9895C6 12.2168 6.08869 12.4348 6.24655 12.5956C6.40442 12.7563 6.61853 12.8466 6.84179 12.8466L20.0394 12.8192C20.0629 12.8192 20.0823 12.8072 20.105 12.8055C20.0303 12.9505 19.9357 13.0838 19.8239 13.2015L16.5586 16.5263C16.4782 16.6054 16.414 16.7 16.3699 16.8045C16.3258 16.9091 16.3026 17.0216 16.3016 17.1354C16.3006 17.2492 16.3219 17.3621 16.3643 17.4674C16.4066 17.5728 16.4691 17.6685 16.5481 17.7489C16.6272 17.8294 16.7211 17.8931 16.8246 17.9362C16.9281 17.9793 17.0389 18.001 17.1507 18C17.2624 17.999 17.3729 17.9753 17.4756 17.9304C17.5783 17.8855 17.6712 17.8202 17.7488 17.7383L21.0141 14.4135C21.6454 13.7705 22 12.8986 22 11.9895C22 11.0803 21.6454 10.2084 21.0141 9.56547H21.0175Z"
											fill="#001F38"
										/>
										<path
											d="M8.125 20.3333H6.375C5.67881 20.3333 5.01113 20.0699 4.51884 19.6011C4.02656 19.1323 3.75 18.4964 3.75 17.8333V6.16667C3.75 5.50363 4.02656 4.86774 4.51884 4.3989C5.01113 3.93006 5.67881 3.66667 6.375 3.66667H8.125C8.35706 3.66667 8.57962 3.57887 8.74372 3.42259C8.90781 3.26631 9 3.05435 9 2.83333C9 2.61232 8.90781 2.40036 8.74372 2.24408C8.57962 2.0878 8.35706 2 8.125 2H6.375C5.2151 2.00132 4.10311 2.44073 3.28294 3.22185C2.46277 4.00296 2.00139 5.062 2 6.16667L2 17.8333C2.00139 18.938 2.46277 19.997 3.28294 20.7782C4.10311 21.5593 5.2151 21.9987 6.375 22H8.125C8.35706 22 8.57962 21.9122 8.74372 21.7559C8.90781 21.5996 9 21.3877 9 21.1667C9 20.9457 8.90781 20.7337 8.74372 20.5774C8.57962 20.4211 8.35706 20.3333 8.125 20.3333Z"
											fill="#001F38"
										/>
									</svg>
								</div>
								<div className="profile-modal__item-text" onClick={logoutClick}>
									Выйти
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					type="button"
					data-fancybox-close=""
					className="fancybox-button fancybox-close-small"
					title="Close"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
					</svg>
				</button>
			</div>
		</SidebarRight>
	)
}

export default ProfileSidebar
