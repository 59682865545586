import { useEffect } from 'react'

import { useNavigate } from 'react-router'
import { logoutF } from 'src/features/logout/model/logout'
import { useAppDispatch } from 'src/hooks/redusHooks'
import { openModal } from 'src/redux/slices/modalSlice'

import { eventEmitter } from './eventEmitter'
import { ErrorEventEmitter } from './type'

export const useErrorHandler = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		eventEmitter.on('request-error', handleRequestError)
		return () => {
			eventEmitter.off('request-error', handleRequestError)
		}
	}, [])

	const handleRequestError = (error: ErrorEventEmitter) => {
		if (error.action === 'modal') {
			dispatch(
				openModal({
					modalType: 'error',
					text: error?.message ? error.message : 'Неизвестная ошибка',
					goBack: error?.goBack || error?.backendAction === 'go-back' || false
				})
			)
		}

		if (error.action === 'logout') {
			logoutF(dispatch)
		}

		if (error.action === 'navigation') {
			navigate(error?.href || 'Error')
		}
	}
}
